<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right;">
        <el-button @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content bgFFF paddingLR20">
      <el-tabs v-model="activeName2">
        <!--<el-tab-pane label="基本信息" name="first">-->
        <h2 class="title tabSty paddingLR20">
          <div class="title_icon"></div>
          基本信息
        </h2>
        <!--<pic-compontent></pic-compontent>-->
        <el-row>
          <el-col :span="8" v-for="v in dataArr" :key="v.name">
            <el-row>
              <el-col :span="9">
                <div class="name">{{ v.name }}</div>
              </el-col>
              <el-col :span="15">
                <div class="value">
                  {{
                    (v.formatter && v.formatter(formInline[v.prop])) ||
                    formInline[v.prop] ||
                    (v.formatter && v.formatter(parkA[v.prop])) ||
                    parkA[v.prop]
                  }}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!--</el-tab-pane>-->
      </el-tabs>
      <el-tabs>
        <h2 class="title tabSty paddingLR20">
          <div class="title_icon"></div>
          后入场车辆信息
        </h2>
        <div style="height: 190px;">
          <div class="carImg">
            <div class="car_img" v-if="parkRecordB.entryFeatPicURL">
              <img
                :src="parkRecordB.entryFeatPicURL"
                width="100%;"
                height="100%"
                @click="showBigImg(parkRecordB.entryFeatPicURL)"
              />
            </div>
          </div>
          <div class="carInfo">
            <el-row>
              <el-col :span="12" v-for="v in outInFoArr" :key="v.name">
                <el-row>
                  <el-col :span="9">
                    <div class="name">{{ v.name }}</div>
                  </el-col>
                  <el-col :span="15">
                    <div class="value">
                      {{
                        (v.formatter && v.formatter(parkRecordB[v.prop])) ||
                        parkRecordB[v.prop]
                      }}
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="9">
                    <div class="name">查看详情</div>
                  </el-col>
                  <el-col :span="15">
                    <div
                      class="value pointer"
                      @click="showDetail(parkRecordB)"
                      style="color: #3d94dd;"
                    >
                      查看
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tabs>
      <el-tabs>
        <h2 class="title tabSty paddingLR20">
          <div class="title_icon"></div>
          先入场车辆信息
        </h2>
        <div style="height: 190px;">
          <div class="carImg">
            <div class="car_img" v-if="parkRecordA.entryFeatPicURL">
              <img
                :src="parkRecordA.entryFeatPicURL"
                width="100%;"
                height="100%"
                @click="showBigImg(parkRecordA.entryFeatPicURL)"
              />
            </div>
          </div>
          <div class="carInfo">
            <el-row>
              <el-col :span="12" v-for="v in inInFoArr" :key="v.name">
                <el-row>
                  <el-col :span="9">
                    <div class="name">{{ v.name }}</div>
                  </el-col>
                  <el-col :span="15">
                    <div class="value">
                      {{
                        (v.formatter && v.formatter(parkRecordA[v.prop])) ||
                        parkRecordA[v.prop]
                      }}
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="9">
                    <div class="name">查看详情</div>
                  </el-col>
                  <el-col :span="15">
                    <div
                      class="value pointer"
                      @click="showDetail(parkRecordA)"
                      style="color: #3d94dd;"
                    >
                      查看
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-tabs>
    </div>
    <!-- 弹窗 -->
    <pic-detail
      :infoSize="4"
      :infoDetail="parkDetail"
      :rowData="rowData"
      :detailFlag="dialogVisible"
      :tableOrder="tableOrder"
      :parkRecordIdTable="parkRecordIdTable"
      @close="dialogVisible = false"
      :total="total1"
      :tableOrderdata="tableOrderdata"
      :parkRecordIdData="parkRecordIdData"
      @getOrder="clildrenHandleCurrentChange"
      :entryPic="entryPic"
      :exitPic="exitPic"
    ></pic-detail>
    <!-- 
    <carpoliceDetail
      :detailFlag="dialogVisible"
      :plateNumber="plateNum"
      :tableData="tableData"
      @close="dialogVisible = false"
    ></carpoliceDetail> -->

    <!--<div class="buttonHandle">-->
    <!--<el-button type="primary" icon="el-icon-search" @click="lookParkRecordList()" >取消</el-button>-->
    <!--</div>-->
  </div>
</template>
<script>
// import picCompontent from "./picCompontent";
import picDetail from "@/components/picDetail/picDetail";
// import carpoliceDetail from "./carpoliceDetail";
import { showBigImage, getDt, dateFormat } from "@/common/js/public.js";
//  import history from './history'
export default {
  name: "hello",
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    return {
      formInline: {},
      parkRecordA: {},
      plateNum: "",
      startTime: dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
      endTime: dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      parkA: [],
      dialogVisible: false,
      carId: "",
      activeName2: "first",
      query: {},
      tableData: [],
      dataArr: [
        {
          name: "报警编号",
          prop: "alarmCode",
        },
        {
          name: "报警类型",
          prop: "type",
          formatter: (type) => {
            if (type == 0) {
              return "超长报警 ";
            } else {
              return "套牌车报警";
            }
          },
        },
        {
          name: "报警时间",
          prop: "reportTime",
        },
      ],
      outInFoArr: [
        {
          name: "车牌号",
          prop: "plateNumber",
        },
        {
          name: "车辆类型",
          prop: "carType",
          formatter: (carType) => {
            if (carType == 0) {
              return "小型车 ";
            } else if (carType == 1) {
              return "中型车";
            } else if (carType == 2) {
              return "大型车";
            }
          },
        },
        {
          name: "停车场名称",
          prop: "parkName",
        },
        {
          name: "泊位号",
          prop: "berthCode",
        },
        {
          name: "入场时间",
          prop: "strEntryTime",
          value: "",
        },
        {
          name: "出场时间",
          prop: "strExitTime",
          value: "",
        },
        {
          name: "停车时间",
          prop: "parkTime",
          value: "",
        },
      ],
      inInFoArr: [
        {
          name: "车牌号",
          prop: "plateNumber",
        },
        {
          name: "车辆类型",
          prop: "carType",
          formatter: (carType) => {
            if (carType == 0) {
              return "小型车 ";
            } else if (carType == 1) {
              return "中型车";
            } else if (carType == 2) {
              return "大型车";
            }
          },
        },
        {
          name: "停车场名称",
          prop: "parkName",
        },
        {
          name: "泊位号",
          prop: "berthCode",
        },
        {
          name: "入场时间",
          prop: "strEntryTime",
          value: "",
        },
        {
          name: "出场时间",
          prop: "strExitTime",
          value: "",
        },
        {
          name: "停车时间",
          prop: "parkTime",
          value: "",
        },
      ],
      entryPlatePicURL: "",
      exitFeatPicURL: "",
      entryFeatPicURL: "",
      parkDetail: [],
      rowData: [],
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        { label: "支付方式", value: "payTypeDesc", width: "" },
        { label: "支付终端", value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        {
          label: "支付方式",
          value: "payTypeName",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      total1: 0,
      tableOrderdata: [],
      parkRecordIdData: [],
      entryPic: [],
      exitPic: [],
      page: 1,
      size: 15,
    };
  },
  methods: {
    // 放大图片
    showBigImg(url) {
      showBigImage(url);
    },
    getDetail() {
      let url = "/acb/2.0/carAlarm/detail/" + this.query.alarmId;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state === 0) {
            this.formInline = res.value;
            this.parkRecordA = this.formInline.parkRecordA;
            this.parkRecordB = this.formInline.parkRecordB;
            this.parkRecordA.plateNumber = this.formInline.plateNumber;
            this.plateNum = this.parkRecordA.plateNumber;
            this.parkA = this.formInline.parkA;
            this.exitFeatPicURL = this.formInline.parkRecordA.exitFullPicURL;
            this.entryFeatPicURL = this.formInline.parkRecordA.entryFullPictURL;
            this.entryPlatePicURL = this.formInline.parkRecordA.entryPlatePicURL;
          }
        });
    },
    searParkRecordList() {
      let url = "/acb/2.0/parkRecord/list";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 10,
            carId: this.query.carId,
            startTime: this.startTime,
            endTime: this.endTime,
            entryOrExit: 1,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    showDetail(data) {
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          // url: "/acb/2.0/picture/getPictureListByOprNum/" + entryOprNum,
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        getDt(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/picture/getPictureListByOprNum/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        getDt(opt);
      }
    },
    // 订单记录
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
  },
  components: {
    // picCompontent,
    // carpoliceDetail,
    picDetail,
    //      history
  },
  created() {
    // this.getDetail()
  },
  mounted() {
    if (this.$route.query.alarmId) {
      this.query = this.$route.query;
    }
    this.getDetail();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="stylus" rel="stylesheet/stylus">
.pointer{
  cursor pointer
}
.el-table .success-row {
  background: #f0f9eb !important;
}
.tableWrapper{
  /*width:800px;*/
  line-height: 44px;
  overflow: hidden;
}
.name{
  background:rgba(249,250,252,1);
  color:#475669;
}
.value{
  padding-left:18px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.name,.value{
  border:1px solid #E0E6ED;
  height: 46px;
  line-height: 46px;
  text-align: center;
}
.carImg{
  float left;
  display: inline-block;
  width: 28%;
  height: 190px;
  border: 1px solid #d9d9d9;
}
.carInfo{
  float right;
  display: inline-block;
  width: 70%;
  margin-left: 10px;
}
.imageLong{
  width: 99.5%;
  height: 260px;
  border: 1px solid #d9d9d9;
}
.buttonHandle{
  text-align: center;
}
.Line{
  position: relative;
}
.lineSize{
  display: inline-block;
  position: relative;
  z-index: 2;
  font-size: 20px;
  color: #CCCCCC;
  background-color: #fff;
  margin: 20px;
  padding: 0 10px;
}
.showLine{
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  bottom: 25px;
  background-color: #e4e7ed;
  /*background-color: rebeccapurple;*/
  z-index: 1;
}
.tabSty{
  height: 42px;
  line-height 42px;
  background-color: #e0e6ed;
  width: 100%;
  margin-bottom 10px;
}
.title_icon{
  margin-top 13px !important;
}
.car_img
  background-size: 100% 100%;
  height 190px;
/*height 200px*/
  /*cursor pointer*/
</style>
